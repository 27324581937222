<!-- eslint-disable vue/no-v-html -->
<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <component
                :is="getFormieComponent('checkbox')"
                v-model="value"
                :input-bindings="getField(field).getInputBindings()"
                value="1"
            >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span
                    v-if="field.descriptionHtml"
                    v-html="field.descriptionHtml"
                />
            </component>
        </component>
    </div>
</template>

<script setup>
defineProps({
    field: {
        type: Object,
        required: true,
    }
});

const value = defineModel({ type: String, required: true });
</script>
